export default [
  {
    "interface": "ac",
    "types": [
      "sl"
    ],
    "lists": [
      {
        "code": "5dragon",
        "type": "sl"
      },
      {
        "code": "5dragondeluxe",
        "type": "sl"
      },
      {
        "code": "5koi",
        "type": "sl"
      },
      {
        "code": "5koideluxe",
        "type": "sl"
      },
      {
        "code": "aladdin",
        "type": "sl"
      },
      {
        "code": "amazingthailand",
        "type": "sl"
      },
      {
        "code": "ancientegyptclassic",
        "type": "sl"
      },
      {
        "code": "anightout",
        "type": "sl"
      },
      {
        "code": "arctictreasure",
        "type": "sl"
      },
      {
        "code": "arctictreasure",
        "type": "sl"
      },
      {
        "code": "aztec",
        "type": "sl"
      },
      {
        "code": "baozhuxuantian",
        "type": "sl"
      },
      {
        "code": "bigshot",
        "type": "sl"
      },
      {
        "code": "boyking",
        "type": "sl"
      },
      {
        "code": "captaintreasure",
        "type": "sl"
      },
      {
        "code": "carracing",
        "type": "sl"
      },
      {
        "code": "cherrylove",
        "type": "sl"
      },
      {
        "code": "circus",
        "type": "sl"
      },
      {
        "code": "cleopatragold",
        "type": "sl"
      },
      {
        "code": "cookiepop",
        "type": "sl"
      },
      {
        "code": "coyotecash",
        "type": "sl"
      },
      {
        "code": "crystal",
        "type": "sl"
      },
      {
        "code": "deserttreasure",
        "type": "sl"
      },
      {
        "code": "dolphinop",
        "type": "sl"
      },
      {
        "code": "dolphinreef",
        "type": "sl"
      },
      {
        "code": "dragonmaiden",
        "type": "sl"
      },
      {
        "code": "eastersurprise",
        "type": "sl"
      },
      {
        "code": "emperorgate",
        "type": "sl"
      },
      {
        "code": "fameandfortune",
        "type": "sl"
      },
      {
        "code": "fashion",
        "type": "sl"
      },
      {
        "code": "feicuigongzhu",
        "type": "sl"
      },
      {
        "code": "fengshen",
        "type": "sl"
      },
      {
        "code": "footballrules",
        "type": "sl"
      },
      {
        "code": "forestdance",
        "type": "sl"
      },
      {
        "code": "fortunelions",
        "type": "sl"
      },
      {
        "code": "fortuneofthefox",
        "type": "sl"
      },
      {
        "code": "fortunepanda",
        "type": "sl"
      },
      {
        "code": "fruit",
        "type": "sl"
      },
      {
        "code": "geishastory",
        "type": "sl"
      },
      {
        "code": "godofwealth",
        "type": "sl"
      },
      {
        "code": "goldenisland",
        "type": "sl"
      },
      {
        "code": "goldenslut",
        "type": "sl"
      },
      {
        "code": "goldentour",
        "type": "sl"
      },
      {
        "code": "greatblue",
        "type": "sl"
      },
      {
        "code": "greatchina",
        "type": "sl"
      },
      {
        "code": "greatmingempire",
        "type": "sl"
      },
      {
        "code": "greatstars",
        "type": "sl"
      },
      {
        "code": "halloweenfortune",
        "type": "sl"
      },
      {
        "code": "highwayking",
        "type": "sl"
      },
      {
        "code": "hockeyleague",
        "type": "sl"
      },
      {
        "code": "iceland",
        "type": "sl"
      },
      {
        "code": "indianmyth",
        "type": "sl"
      },
      {
        "code": "irishluck",
        "type": "sl"
      },
      {
        "code": "japan",
        "type": "sl"
      },
      {
        "code": "jinqianwa",
        "type": "sl"
      },
      {
        "code": "kimochi",
        "type": "sl"
      },
      {
        "code": "kingderby",
        "type": "sl"
      },
      {
        "code": "laura",
        "type": "sl"
      },
      {
        "code": "lottomadness",
        "type": "sl"
      },
      {
        "code": "madamedestiny",
        "type": "sl"
      },
      {
        "code": "marilynmonroe",
        "type": "sl"
      },
      {
        "code": "mermaidjewel",
        "type": "sl"
      },
      {
        "code": "moe",
        "type": "sl"
      },
      {
        "code": "motorbike",
        "type": "sl"
      },
      {
        "code": "mysticdragon",
        "type": "sl"
      },
      {
        "code": "nana",
        "type": "sl"
      },
      {
        "code": "niannianyouyu",
        "type": "sl"
      },
      {
        "code": "nitro",
        "type": "sl"
      },
      {
        "code": "orient",
        "type": "sl"
      },
      {
        "code": "panjinlian",
        "type": "sl"
      },
      {
        "code": "panthermoon",
        "type": "sl"
      },
      {
        "code": "penguinvacation",
        "type": "sl"
      },
      {
        "code": "puregold",
        "type": "sl"
      },
      {
        "code": "ranchstory",
        "type": "sl"
      },
      {
        "code": "rara",
        "type": "sl"
      },
      {
        "code": "rion",
        "type": "sl"
      },
      {
        "code": "romeandglory",
        "type": "sl"
      },
      {
        "code": "safariheat",
        "type": "sl"
      },
      {
        "code": "santasurprise",
        "type": "sl"
      },
      {
        "code": "seacaptain",
        "type": "sl"
      },
      {
        "code": "seasongreetings",
        "type": "sl"
      },
      {
        "code": "shuangxi",
        "type": "sl"
      },
      {
        "code": "silentsamurai",
        "type": "sl"
      },
      {
        "code": "silverbullet",
        "type": "sl"
      },
      {
        "code": "sixiang",
        "type": "sl"
      },
      {
        "code": "sora",
        "type": "sl"
      },
      {
        "code": "sparta",
        "type": "sl"
      },
      {
        "code": "spartan",
        "type": "sl"
      },
      {
        "code": "steamtower",
        "type": "sl"
      },
      {
        "code": "stripnight",
        "type": "sl"
      },
      {
        "code": "sunwukong",
        "type": "sl"
      },
      {
        "code": "t-rex",
        "type": "sl"
      },
      {
        "code": "talesofegypt",
        "type": "sl"
      },
      {
        "code": "thaiparadise",
        "type": "sl"
      },
      {
        "code": "thediscovery",
        "type": "sl"
      },
      {
        "code": "threekingdoms",
        "type": "sl"
      },
      {
        "code": "thunderbolt1",
        "type": "sl"
      },
      {
        "code": "topgun",
        "type": "sl"
      },
      {
        "code": "tripletwister",
        "type": "sl"
      },
      {
        "code": "wealth",
        "type": "sl"
      },
      {
        "code": "wildfox",
        "type": "sl"
      },
      {
        "code": "wolfhunter",
        "type": "sl"
      },
      {
        "code": "wukong1",
        "type": "sl"
      },
      {
        "code": "yingcaishen",
        "type": "sl"
      },
      {
        "code": "zhaocaijinbao",
        "type": "sl"
      },
      {
        "code": "zhaocaitongzi",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "ax",
    "types": [
      "sl"
    ],
    "lists": [
      {
        "code": "140000",
        "type": "sl"
      },
      {
        "code": "140001",
        "type": "sl"
      },
      {
        "code": "140002",
        "type": "sl"
      },
      {
        "code": "140003",
        "type": "sl"
      },
      {
        "code": "140004",
        "type": "sl"
      },
      {
        "code": "140005",
        "type": "sl"
      },
      {
        "code": "140006",
        "type": "sl"
      },
      {
        "code": "140007",
        "type": "sl"
      },
      {
        "code": "140008",
        "type": "sl"
      },
      {
        "code": "140009",
        "type": "sl"
      },
      {
        "code": "140010",
        "type": "sl"
      },
      {
        "code": "140011",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "jk",
    "types": [
      "sl",
      "fh",
      "ar",
      "tb"
    ],
    "lists": [
      {
        "code": "113qm5xnhxoqn",
        "type": "sl"
      },
      {
        "code": "1jeqx59c7ztqg",
        "type": "fh"
      },
      {
        "code": "1q36p58phmt6y",
        "type": "sl"
      },
      {
        "code": "1ru5x5zx7us6r",
        "type": "sl"
      },
      {
        "code": "1wb58azdhdo6c",
        "type": "ar"
      },
      {
        "code": "1wt58azdhdo6c",
        "type": "sl"
      },
      {
        "code": "1ynmp4xq79sq4",
        "type": "ar"
      },
      {
        "code": "3erm9p7wssiks",
        "type": "sl"
      },
      {
        "code": "3fx69pizs144w",
        "type": "sl"
      },
      {
        "code": "3hj4fkfji4z4a",
        "type": "sl"
      },
      {
        "code": "3uim5ppkiqwk1",
        "type": "tb"
      },
      {
        "code": "3yfmucpss64mk",
        "type": "sl"
      },
      {
        "code": "43bx3e7ywgukq",
        "type": "sl"
      },
      {
        "code": "4akkze7ywgukq",
        "type": "sl"
      },
      {
        "code": "4d5kdkpqi6sk4",
        "type": "sl"
      },
      {
        "code": "4eekxm7cistkg",
        "type": "sl"
      },
      {
        "code": "4jdxbm7cistkg",
        "type": "sl"
      },
      {
        "code": "4omkmmpnwqokn",
        "type": "fh"
      },
      {
        "code": "4tyxfmpnwqokn",
        "type": "sl"
      },
      {
        "code": "55hj8ghaugxj6",
        "type": "sl"
      },
      {
        "code": "5864tji8w113w",
        "type": "sl"
      },
      {
        "code": "5bgx7epgw61kk",
        "type": "sl"
      },
      {
        "code": "5cx47jffukp3o",
        "type": "sl"
      },
      {
        "code": "5ii9zgw5unc3h",
        "type": "sl"
      },
      {
        "code": "5m6k9j7rwspjs",
        "type": "sl"
      },
      {
        "code": "5trxijc4uqcj1",
        "type": "sl"
      },
      {
        "code": "5ypkuepgw61kk",
        "type": "sl"
      },
      {
        "code": "69xaiyrbo4dae",
        "type": "sl"
      },
      {
        "code": "6c5apdrpokbay",
        "type": "sl"
      },
      {
        "code": "6jupbdhctsbeg",
        "type": "sl"
      },
      {
        "code": "6o5emdcnoqyen",
        "type": "sl"
      },
      {
        "code": "6po7ddrpokbay",
        "type": "sl"
      },
      {
        "code": "79mafnrjt48aa",
        "type": "sl"
      },
      {
        "code": "7b6c7rcs16kjk",
        "type": "sl"
      },
      {
        "code": "7cz37fritkfao",
        "type": "sl"
      },
      {
        "code": "7f9h9fwz11kaw",
        "type": "sl"
      },
      {
        "code": "7rw3tfwz11kaw",
        "type": "sl"
      },
      {
        "code": "7tccifcktqre1",
        "type": "sl"
      },
      {
        "code": "84igeq3a8r9d6",
        "type": "sl"
      },
      {
        "code": "856dgq3a8r9d6",
        "type": "tb"
      },
      {
        "code": "86burqb38a9ua",
        "type": "sl"
      },
      {
        "code": "8d7r1okge7nrk",
        "type": "fh"
      },
      {
        "code": "8kzbot4rew7ds",
        "type": "sl"
      },
      {
        "code": "8nsbhokge7nrk",
        "type": "sl"
      },
      {
        "code": "8rqwot18etnuw",
        "type": "sl"
      },
      {
        "code": "8u9r4tj48chd1",
        "type": "sl"
      },
      {
        "code": "93ine65axf986",
        "type": "sl"
      },
      {
        "code": "96k1k6d3x39za",
        "type": "sl"
      },
      {
        "code": "99bzr6d3x39za",
        "type": "sl"
      },
      {
        "code": "9fz79bw8o1nzw",
        "type": "ar"
      },
      {
        "code": "9ii7s6u5xbhzh",
        "type": "sl"
      },
      {
        "code": "9mqe9bhroi78s",
        "type": "sl"
      },
      {
        "code": "9upe5bm4xph81",
        "type": "sl"
      },
      {
        "code": "9w6aa6u5xbhzh",
        "type": "sl"
      },
      {
        "code": "9xpa7brfxj7zo",
        "type": "sl"
      },
      {
        "code": "a7q65cfts455e",
        "type": "sl"
      },
      {
        "code": "abkqpqp6z66m4",
        "type": "sl"
      },
      {
        "code": "ahf5icfts455e",
        "type": "sl"
      },
      {
        "code": "aij68ciusna5c",
        "type": "sl"
      },
      {
        "code": "aodmmxp1sqamn",
        "type": "sl"
      },
      {
        "code": "ary5bxi9z165r",
        "type": "fh"
      },
      {
        "code": "ateqfxp1sqamn",
        "type": "sl"
      },
      {
        "code": "awn5jciusna5c",
        "type": "sl"
      },
      {
        "code": "axt5pxf7sk35y",
        "type": "sl"
      },
      {
        "code": "b1cnw7mkppwg1",
        "type": "sl"
      },
      {
        "code": "b5ggg45epfzg6",
        "type": "sl"
      },
      {
        "code": "b8rzo7uzqt4sw",
        "type": "fh"
      },
      {
        "code": "bcizh7dipjiso",
        "type": "sl"
      },
      {
        "code": "bes8675wqiigs",
        "type": "sl"
      },
      {
        "code": "bmr8675wqiigs",
        "type": "sl"
      },
      {
        "code": "bwwza4umpbwsh",
        "type": "sl"
      },
      {
        "code": "byz81hmsq748k",
        "type": "sl"
      },
      {
        "code": "bzgza4umpbwsh",
        "type": "sl"
      },
      {
        "code": "c41bsraonrmbq",
        "type": "sl"
      },
      {
        "code": "c85wq8o9doqtr",
        "type": "sl"
      },
      {
        "code": "cd4rcge6dhqb4",
        "type": "sl"
      },
      {
        "code": "cuarr8e1ncebn",
        "type": "sl"
      },
      {
        "code": "cxawn8y7nejty",
        "type": "ar"
      },
      {
        "code": "cz3wgrounyetc",
        "type": "ar"
      },
      {
        "code": "d4fyes4amfxf6",
        "type": "sl"
      },
      {
        "code": "d5qfgs4amfxf6",
        "type": "sl"
      },
      {
        "code": "d8cso3u8ct1iw",
        "type": "sl"
      },
      {
        "code": "dc7sh3dfmjpio",
        "type": "tb"
      },
      {
        "code": "ddpg1amgc71gk",
        "type": "fh"
      },
      {
        "code": "dhdirsn3m3xia",
        "type": "sl"
      },
      {
        "code": "dkzdo35rcipfs",
        "type": "sl"
      },
      {
        "code": "dngdhamgc71gk",
        "type": "ar"
      },
      {
        "code": "dxxsh3dfmjpio",
        "type": "sl"
      },
      {
        "code": "e4ndsc3ogr5dq",
        "type": "sl"
      },
      {
        "code": "ebudnqj68h6d4",
        "type": "sl"
      },
      {
        "code": "ef1uyxt98o6ur",
        "type": "sl"
      },
      {
        "code": "exesnxb7ge3uy",
        "type": "sl"
      },
      {
        "code": "fk9yoi4wkifrs",
        "type": "sl"
      },
      {
        "code": "fn6yhwksk7kfk",
        "type": "ar"
      },
      {
        "code": "foff4ikkjprr1",
        "type": "sl"
      },
      {
        "code": "fqho1inijjfwo",
        "type": "sl"
      },
      {
        "code": "fwria11mjbrwh",
        "type": "sl"
      },
      {
        "code": "g54rso4yefdrq",
        "type": "fh"
      },
      {
        "code": "g8awyu1xjtgwr",
        "type": "sl"
      },
      {
        "code": "gd3rn1kqj7gr4",
        "type": "sl"
      },
      {
        "code": "gkubyu4cjibrg",
        "type": "sl"
      },
      {
        "code": "gn1bc1kqj7gr4",
        "type": "sl"
      },
      {
        "code": "gqotnunpejbwy",
        "type": "sl"
      },
      {
        "code": "gsttgo1debywc",
        "type": "sl"
      },
      {
        "code": "h33c3rho1gmjq",
        "type": "sl"
      },
      {
        "code": "ha1jzrho1gmjq",
        "type": "sl"
      },
      {
        "code": "hb4cpgc6u6qj4",
        "type": "sl"
      },
      {
        "code": "hcu3p8r71kj3y",
        "type": "sl"
      },
      {
        "code": "hf5hx8w9u1q3r",
        "type": "sl"
      },
      {
        "code": "hxb3p8r71kj3y",
        "type": "tb"
      },
      {
        "code": "i4rc816e388c6",
        "type": "sl"
      },
      {
        "code": "ie9eti6w4zfcs",
        "type": "sl"
      },
      {
        "code": "igg7tisz4ukhw",
        "type": "sl"
      },
      {
        "code": "ioheiiqk3xrc1",
        "type": "sl"
      },
      {
        "code": "ipz77igi3mfho",
        "type": "sl"
      },
      {
        "code": "itzp5iqk3xrc1",
        "type": "sl"
      },
      {
        "code": "iw7az1sm3drhh",
        "type": "sl"
      },
      {
        "code": "j3wngk3efrzn6",
        "type": "tb"
      },
      {
        "code": "j6j1rkbjfaz1a",
        "type": "sl"
      },
      {
        "code": "j8xs6ptzgo41w",
        "type": "ar"
      },
      {
        "code": "j9nzkkbjfaz1a",
        "type": "sl"
      },
      {
        "code": "jbzd1cjsgh4dk",
        "type": "sl"
      },
      {
        "code": "jpiuhpbifei1o",
        "type": "sl"
      },
      {
        "code": "jsguaktmfyw1h",
        "type": "sl"
      },
      {
        "code": "jzpssktmfyw1h",
        "type": "sl"
      },
      {
        "code": "k3anse3yrrunq",
        "type": "sl"
      },
      {
        "code": "k9gz4ebbrau1e",
        "type": "sl"
      },
      {
        "code": "kdn8ckjqfhsn4",
        "type": "sl"
      },
      {
        "code": "kf41ymtxfos1r",
        "type": "sl"
      },
      {
        "code": "kia1eetdryo1c",
        "type": "sl"
      },
      {
        "code": "kk8nqm3cfwtng",
        "type": "fh"
      },
      {
        "code": "knjnnkjqfhsn4",
        "type": "sl"
      },
      {
        "code": "kqm1cmbpret1y",
        "type": "sl"
      },
      {
        "code": "kxyznmbpret1y",
        "type": "sl"
      },
      {
        "code": "m1xn4je4dccb1",
        "type": "sl"
      },
      {
        "code": "m94wkgy3daxta",
        "type": "sl"
      },
      {
        "code": "mur8wje4dccb1",
        "type": "sl"
      },
      {
        "code": "n1ydr5mncpogn",
        "type": "sl"
      },
      {
        "code": "naagsa5ycfugq",
        "type": "sl"
      },
      {
        "code": "ne4gq55cpitgg",
        "type": "sl"
      },
      {
        "code": "nh9swadbc3use",
        "type": "sl"
      },
      {
        "code": "nqyun5dpcjtsy",
        "type": "sl"
      },
      {
        "code": "nzkseaudcbosc",
        "type": "fh"
      },
      {
        "code": "o3nxzh9o685xq",
        "type": "sl"
      },
      {
        "code": "o7f9ih8t6559e",
        "type": "sl"
      },
      {
        "code": "oajk3h9o685xq",
        "type": "sl"
      },
      {
        "code": "ofy9b9z99u69r",
        "type": "sl"
      },
      {
        "code": "oqt9p9876m39y",
        "type": "sl"
      },
      {
        "code": "p63ornyjba8oa",
        "type": "fh"
      },
      {
        "code": "p91iknyjba8oa",
        "type": "ar"
      },
      {
        "code": "pd6rhresnhkbk",
        "type": "sl"
      },
      {
        "code": "pirtanombyroh",
        "type": "sl"
      },
      {
        "code": "pxcw1fyibefoo",
        "type": "sl"
      },
      {
        "code": "pxhw1fyibefoo",
        "type": "ar"
      },
      {
        "code": "q9gi4yybyadoe",
        "type": "sl"
      },
      {
        "code": "qd1fcneqbhgy4",
        "type": "sl"
      },
      {
        "code": "qieoeyodyyyoc",
        "type": "sl"
      },
      {
        "code": "qn3ynneqbhgy4",
        "type": "sl"
      },
      {
        "code": "qq5ocdypyeboy",
        "type": "fh"
      },
      {
        "code": "quofrdenycyyn",
        "type": "sl"
      },
      {
        "code": "qxoindypyeboy",
        "type": "sl"
      },
      {
        "code": "r43yaw4okfmfq",
        "type": "ar"
      },
      {
        "code": "r8oiyz19mtqir",
        "type": "sl"
      },
      {
        "code": "rh8iwwntk3mie",
        "type": "sl"
      },
      {
        "code": "rqaonzn7kjjiy",
        "type": "sl"
      },
      {
        "code": "rsjogw1ukbeic",
        "type": "sl"
      },
      {
        "code": "ruufkzk1kpefn",
        "type": "sl"
      },
      {
        "code": "s6xhiogba5dhe",
        "type": "sl"
      },
      {
        "code": "s77hiogba5dhe",
        "type": "sl"
      },
      {
        "code": "satj3o6ya8dcq",
        "type": "sl"
      },
      {
        "code": "soojfuqnaxycn",
        "type": "sl"
      },
      {
        "code": "srd3xusx3ughr",
        "type": "sl"
      },
      {
        "code": "st5cmuqnaxycn",
        "type": "fh"
      },
      {
        "code": "swt38osdadyhc",
        "type": "sl"
      },
      {
        "code": "sy1jp1qq39gc4",
        "type": "ar"
      },
      {
        "code": "t656f48j75z6a",
        "type": "sl"
      },
      {
        "code": "tbfxuhxs694xk",
        "type": "sl"
      },
      {
        "code": "tocki7xk7xwq1",
        "type": "ar"
      },
      {
        "code": "tqi9778i7mi6o",
        "type": "sl"
      },
      {
        "code": "u17q53q45xcp1",
        "type": "sl"
      },
      {
        "code": "u6d7fsg355x7a",
        "type": "sl"
      },
      {
        "code": "uafejs6a58xp6",
        "type": "sl"
      },
      {
        "code": "ufc6t3z8hu17w",
        "type": "sl"
      },
      {
        "code": "ur8593z8hu17w",
        "type": "sl"
      },
      {
        "code": "uwf5zss55dc7h",
        "type": "sl"
      },
      {
        "code": "w4ypzw6o48mpq",
        "type": "sl"
      },
      {
        "code": "wcaadzg74mj7y",
        "type": "sl"
      },
      {
        "code": "wfo7bzs95uq7r",
        "type": "sl"
      },
      {
        "code": "wi17jwsu4de7c",
        "type": "fh"
      },
      {
        "code": "wpu7pzg74mj7y",
        "type": "sl"
      },
      {
        "code": "wr5axzs95uq7r",
        "type": "sl"
      },
      {
        "code": "wtupmzq14xepn",
        "type": "sl"
      },
      {
        "code": "ww3a8wsu4de7c",
        "type": "sl"
      },
      {
        "code": "wykepsq659qp4",
        "type": "sl"
      },
      {
        "code": "x46x869a989x6",
        "type": "sl"
      },
      {
        "code": "x5ikj69a989x6",
        "type": "sl"
      },
      {
        "code": "xbxy1yegyhnyk",
        "type": "sl"
      },
      {
        "code": "xkhy6baryz7xs",
        "type": "fh"
      },
      {
        "code": "xmzfobaryz7xs",
        "type": "sl"
      },
      {
        "code": "xq9ohbyf9m79o",
        "type": "fh"
      },
      {
        "code": "xtpy4bx49xhx1",
        "type": "sl"
      },
      {
        "code": "xxwi1byf9m79o",
        "type": "ar"
      },
      {
        "code": "y5n8sh5oqf58q",
        "type": "sl"
      },
      {
        "code": "y6q14hdtq35ze",
        "type": "sl"
      },
      {
        "code": "ykmny95hxi38g",
        "type": "ar"
      },
      {
        "code": "yqe1n9d7qj3zy",
        "type": "sl"
      },
      {
        "code": "yr1zy9u9xt6zr",
        "type": "tb"
      },
      {
        "code": "ywozehuuqbazc",
        "type": "sl"
      },
      {
        "code": "yxdzc9d7qj3zy",
        "type": "sl"
      },
      {
        "code": "z1pc5tp4zqhm1",
        "type": "sl"
      },
      {
        "code": "z7k6mqf3z495a",
        "type": "ar"
      },
      {
        "code": "zygj7oqga9nck",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "nl",
    "types": [
      "sl"
    ],
    "lists": [
      {
        "code": "180000",
        "type": "sl"
      },
      {
        "code": "180001",
        "type": "sl"
      },
      {
        "code": "180002",
        "type": "sl"
      },
      {
        "code": "180003",
        "type": "sl"
      },
      {
        "code": "180004",
        "type": "sl"
      },
      {
        "code": "180005",
        "type": "sl"
      },
      {
        "code": "180006",
        "type": "sl"
      },
      {
        "code": "180007",
        "type": "sl"
      },
      {
        "code": "180008",
        "type": "sl"
      },
      {
        "code": "180009",
        "type": "sl"
      },
      {
        "code": "180010",
        "type": "sl"
      },
      {
        "code": "180011",
        "type": "sl"
      },
      {
        "code": "180012",
        "type": "sl"
      },
      {
        "code": "180013",
        "type": "sl"
      },
      {
        "code": "180014",
        "type": "sl"
      },
      {
        "code": "180015",
        "type": "sl"
      },
      {
        "code": "180016",
        "type": "sl"
      },
      {
        "code": "180017",
        "type": "sl"
      },
      {
        "code": "180018",
        "type": "sl"
      },
      {
        "code": "180019",
        "type": "sl"
      },
      {
        "code": "180020",
        "type": "sl"
      },
      {
        "code": "180021",
        "type": "sl"
      },
      {
        "code": "180022",
        "type": "sl"
      },
      {
        "code": "180023",
        "type": "sl"
      },
      {
        "code": "180024",
        "type": "sl"
      },
      {
        "code": "180025",
        "type": "sl"
      },
      {
        "code": "180026",
        "type": "sl"
      },
      {
        "code": "180027",
        "type": "sl"
      },
      {
        "code": "180028",
        "type": "sl"
      },
      {
        "code": "180029",
        "type": "sl"
      },
      {
        "code": "180030",
        "type": "sl"
      },
      {
        "code": "180031",
        "type": "sl"
      },
      {
        "code": "180032",
        "type": "sl"
      },
      {
        "code": "180033",
        "type": "sl"
      },
      {
        "code": "180034",
        "type": "sl"
      },
      {
        "code": "180035",
        "type": "sl"
      },
      {
        "code": "180036",
        "type": "sl"
      },
      {
        "code": "180037",
        "type": "sl"
      },
      {
        "code": "180038",
        "type": "sl"
      },
      {
        "code": "180039",
        "type": "sl"
      },
      {
        "code": "180040",
        "type": "sl"
      },
      {
        "code": "180041",
        "type": "sl"
      },
      {
        "code": "180042",
        "type": "sl"
      },
      {
        "code": "180043",
        "type": "sl"
      },
      {
        "code": "180044",
        "type": "sl"
      },
      {
        "code": "180045",
        "type": "sl"
      },
      {
        "code": "180046",
        "type": "sl"
      },
      {
        "code": "180047",
        "type": "sl"
      },
      {
        "code": "180096",
        "type": "sl"
      },
      {
        "code": "180097",
        "type": "sl"
      },
      {
        "code": "180100",
        "type": "sl"
      },
      {
        "code": "180101",
        "type": "sl"
      },
      {
        "code": "180104",
        "type": "sl"
      },
      {
        "code": "180106",
        "type": "sl"
      },
      {
        "code": "180108",
        "type": "sl"
      },
      {
        "code": "180112",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "pn",
    "types": [
      "sl",
      "tb"
    ],
    "lists": [
      {
        "code": "160000",
        "type": "sl"
      },
      {
        "code": "160001",
        "type": "tb"
      },
      {
        "code": "160002",
        "type": "sl"
      },
      {
        "code": "160003",
        "type": "sl"
      },
      {
        "code": "160004",
        "type": "sl"
      },
      {
        "code": "160005",
        "type": "sl"
      },
      {
        "code": "160006",
        "type": "sl"
      },
      {
        "code": "160007",
        "type": "sl"
      },
      {
        "code": "160008",
        "type": "sl"
      },
      {
        "code": "160009",
        "type": "sl"
      },
      {
        "code": "160010",
        "type": "sl"
      },
      {
        "code": "160011",
        "type": "sl"
      },
      {
        "code": "160012",
        "type": "sl"
      },
      {
        "code": "160015",
        "type": "sl"
      },
      {
        "code": "160016",
        "type": "sl"
      },
      {
        "code": "160017",
        "type": "sl"
      },
      {
        "code": "160019",
        "type": "sl"
      },
      {
        "code": "160020",
        "type": "sl"
      },
      {
        "code": "160022",
        "type": "sl"
      },
      {
        "code": "160023",
        "type": "sl"
      },
      {
        "code": "160024",
        "type": "sl"
      },
      {
        "code": "160027",
        "type": "sl"
      },
      {
        "code": "160028",
        "type": "sl"
      },
      {
        "code": "160029",
        "type": "sl"
      },
      {
        "code": "160031",
        "type": "sl"
      },
      {
        "code": "160032",
        "type": "sl"
      },
      {
        "code": "160033",
        "type": "sl"
      },
      {
        "code": "160034",
        "type": "sl"
      },
      {
        "code": "160036",
        "type": "sl"
      },
      {
        "code": "160037",
        "type": "sl"
      },
      {
        "code": "160038",
        "type": "sl"
      },
      {
        "code": "160039",
        "type": "sl"
      },
      {
        "code": "160040",
        "type": "sl"
      },
      {
        "code": "160041",
        "type": "sl"
      },
      {
        "code": "160042",
        "type": "sl"
      },
      {
        "code": "160043",
        "type": "sl"
      },
      {
        "code": "160045",
        "type": "sl"
      },
      {
        "code": "160046",
        "type": "sl"
      },
      {
        "code": "160047",
        "type": "sl"
      },
      {
        "code": "160048",
        "type": "sl"
      },
      {
        "code": "160050",
        "type": "sl"
      },
      {
        "code": "160051",
        "type": "sl"
      },
      {
        "code": "160052",
        "type": "sl"
      },
      {
        "code": "160053",
        "type": "sl"
      },
      {
        "code": "160054",
        "type": "sl"
      },
      {
        "code": "160055",
        "type": "sl"
      },
      {
        "code": "160058",
        "type": "sl"
      },
      {
        "code": "160059",
        "type": "sl"
      },
      {
        "code": "160060",
        "type": "sl"
      },
      {
        "code": "160061",
        "type": "sl"
      },
      {
        "code": "160062",
        "type": "sl"
      },
      {
        "code": "160063",
        "type": "sl"
      },
      {
        "code": "160065",
        "type": "sl"
      },
      {
        "code": "160066",
        "type": "sl"
      },
      {
        "code": "160067",
        "type": "sl"
      },
      {
        "code": "160069",
        "type": "sl"
      },
      {
        "code": "160070",
        "type": "sl"
      },
      {
        "code": "160071",
        "type": "sl"
      },
      {
        "code": "160073",
        "type": "sl"
      },
      {
        "code": "160074",
        "type": "sl"
      },
      {
        "code": "160075",
        "type": "sl"
      },
      {
        "code": "160076",
        "type": "sl"
      },
      {
        "code": "160077",
        "type": "sl"
      },
      {
        "code": "160079",
        "type": "sl"
      },
      {
        "code": "160080",
        "type": "sl"
      },
      {
        "code": "160081",
        "type": "sl"
      },
      {
        "code": "160082",
        "type": "sl"
      },
      {
        "code": "160083",
        "type": "sl"
      },
      {
        "code": "160086",
        "type": "sl"
      },
      {
        "code": "160088",
        "type": "sl"
      },
      {
        "code": "160089",
        "type": "sl"
      },
      {
        "code": "160090",
        "type": "sl"
      },
      {
        "code": "160091",
        "type": "sl"
      },
      {
        "code": "160092",
        "type": "sl"
      },
      {
        "code": "160097",
        "type": "sl"
      },
      {
        "code": "160098",
        "type": "sl"
      },
      {
        "code": "160099",
        "type": "sl"
      },
      {
        "code": "160100",
        "type": "sl"
      },
      {
        "code": "160101",
        "type": "sl"
      },
      {
        "code": "160102",
        "type": "sl"
      },
      {
        "code": "160105",
        "type": "sl"
      },
      {
        "code": "160106",
        "type": "sl"
      },
      {
        "code": "160108",
        "type": "sl"
      },
      {
        "code": "160109",
        "type": "sl"
      },
      {
        "code": "160110",
        "type": "sl"
      },
      {
        "code": "160111",
        "type": "sl"
      },
      {
        "code": "160112",
        "type": "sl"
      },
      {
        "code": "160113",
        "type": "sl"
      },
      {
        "code": "160114",
        "type": "sl"
      },
      {
        "code": "160116",
        "type": "sl"
      },
      {
        "code": "160117",
        "type": "sl"
      },
      {
        "code": "160118",
        "type": "sl"
      },
      {
        "code": "160119",
        "type": "sl"
      },
      {
        "code": "160120",
        "type": "sl"
      },
      {
        "code": "160121",
        "type": "sl"
      },
      {
        "code": "160122",
        "type": "sl"
      },
      {
        "code": "160123",
        "type": "sl"
      },
      {
        "code": "160124",
        "type": "sl"
      },
      {
        "code": "160125",
        "type": "tb"
      },
      {
        "code": "160126",
        "type": "sl"
      },
      {
        "code": "160127",
        "type": "tb"
      },
      {
        "code": "160128",
        "type": "sl"
      },
      {
        "code": "160129",
        "type": "sl"
      },
      {
        "code": "160130",
        "type": "sl"
      },
      {
        "code": "160131",
        "type": "sl"
      },
      {
        "code": "160132",
        "type": "sl"
      },
      {
        "code": "160133",
        "type": "sl"
      },
      {
        "code": "160134",
        "type": "sl"
      },
      {
        "code": "160136",
        "type": "sl"
      },
      {
        "code": "160137",
        "type": "sl"
      },
      {
        "code": "160138",
        "type": "sl"
      },
      {
        "code": "160139",
        "type": "sl"
      },
      {
        "code": "160140",
        "type": "sl"
      },
      {
        "code": "160141",
        "type": "sl"
      },
      {
        "code": "160142",
        "type": "sl"
      },
      {
        "code": "160143",
        "type": "sl"
      },
      {
        "code": "160144",
        "type": "sl"
      },
      {
        "code": "160145",
        "type": "sl"
      },
      {
        "code": "160146",
        "type": "sl"
      },
      {
        "code": "160147",
        "type": "sl"
      },
      {
        "code": "160148",
        "type": "sl"
      },
      {
        "code": "160149",
        "type": "sl"
      },
      {
        "code": "160150",
        "type": "sl"
      },
      {
        "code": "160151",
        "type": "sl"
      },
      {
        "code": "160153",
        "type": "sl"
      },
      {
        "code": "160154",
        "type": "sl"
      },
      {
        "code": "160155",
        "type": "sl"
      },
      {
        "code": "160156",
        "type": "sl"
      },
      {
        "code": "160157",
        "type": "sl"
      },
      {
        "code": "160158",
        "type": "sl"
      },
      {
        "code": "160159",
        "type": "sl"
      },
      {
        "code": "160160",
        "type": "sl"
      },
      {
        "code": "160161",
        "type": "sl"
      },
      {
        "code": "160162",
        "type": "sl"
      },
      {
        "code": "160163",
        "type": "sl"
      },
      {
        "code": "160164",
        "type": "sl"
      },
      {
        "code": "160165",
        "type": "sl"
      },
      {
        "code": "160166",
        "type": "sl"
      },
      {
        "code": "160167",
        "type": "sl"
      },
      {
        "code": "160168",
        "type": "sl"
      },
      {
        "code": "160169",
        "type": "sl"
      },
      {
        "code": "160171",
        "type": "sl"
      },
      {
        "code": "160173",
        "type": "sl"
      },
      {
        "code": "160174",
        "type": "sl"
      },
      {
        "code": "160176",
        "type": "sl"
      },
      {
        "code": "160177",
        "type": "sl"
      },
      {
        "code": "160178",
        "type": "sl"
      },
      {
        "code": "160179",
        "type": "sl"
      },
      {
        "code": "160180",
        "type": "sl"
      },
      {
        "code": "160181",
        "type": "tb"
      },
      {
        "code": "160182",
        "type": "sl"
      },
      {
        "code": "160183",
        "type": "sl"
      },
      {
        "code": "160186",
        "type": "sl"
      },
      {
        "code": "160187",
        "type": "sl"
      },
      {
        "code": "160188",
        "type": "sl"
      },
      {
        "code": "160189",
        "type": "sl"
      },
      {
        "code": "160190",
        "type": "sl"
      },
      {
        "code": "160191",
        "type": "sl"
      },
      {
        "code": "160192",
        "type": "sl"
      },
      {
        "code": "160193",
        "type": "sl"
      },
      {
        "code": "160194",
        "type": "sl"
      },
      {
        "code": "160195",
        "type": "sl"
      },
      {
        "code": "160196",
        "type": "sl"
      },
      {
        "code": "160197",
        "type": "sl"
      },
      {
        "code": "160199",
        "type": "sl"
      },
      {
        "code": "160201",
        "type": "sl"
      },
      {
        "code": "160202",
        "type": "sl"
      },
      {
        "code": "160203",
        "type": "sl"
      },
      {
        "code": "160204",
        "type": "sl"
      },
      {
        "code": "160205",
        "type": "sl"
      },
      {
        "code": "160206",
        "type": "sl"
      },
      {
        "code": "160207",
        "type": "sl"
      },
      {
        "code": "160208",
        "type": "sl"
      },
      {
        "code": "160209",
        "type": "sl"
      },
      {
        "code": "160210",
        "type": "sl"
      },
      {
        "code": "160213",
        "type": "sl"
      },
      {
        "code": "160214",
        "type": "sl"
      },
      {
        "code": "160215",
        "type": "sl"
      },
      {
        "code": "160216",
        "type": "sl"
      },
      {
        "code": "160218",
        "type": "sl"
      },
      {
        "code": "160219",
        "type": "sl"
      },
      {
        "code": "160220",
        "type": "sl"
      },
      {
        "code": "160221",
        "type": "sl"
      },
      {
        "code": "160222",
        "type": "sl"
      },
      {
        "code": "160223",
        "type": "sl"
      },
      {
        "code": "160224",
        "type": "sl"
      },
      {
        "code": "160225",
        "type": "sl"
      },
      {
        "code": "160226",
        "type": "sl"
      },
      {
        "code": "160228",
        "type": "sl"
      },
      {
        "code": "160229",
        "type": "sl"
      },
      {
        "code": "160230",
        "type": "sl"
      },
      {
        "code": "160231",
        "type": "sl"
      },
      {
        "code": "160232",
        "type": "sl"
      },
      {
        "code": "160233",
        "type": "sl"
      },
      {
        "code": "160234",
        "type": "sl"
      },
      {
        "code": "160235",
        "type": "sl"
      },
      {
        "code": "160236",
        "type": "sl"
      },
      {
        "code": "160237",
        "type": "sl"
      },
      {
        "code": "160238",
        "type": "sl"
      },
      {
        "code": "160239",
        "type": "sl"
      },
      {
        "code": "160240",
        "type": "sl"
      },
      {
        "code": "160241",
        "type": "sl"
      },
      {
        "code": "160242",
        "type": "sl"
      },
      {
        "code": "160243",
        "type": "sl"
      },
      {
        "code": "160244",
        "type": "sl"
      },
      {
        "code": "160245",
        "type": "sl"
      },
      {
        "code": "160247",
        "type": "sl"
      },
      {
        "code": "160253",
        "type": "sl"
      },
      {
        "code": "160254",
        "type": "sl"
      },
      {
        "code": "160255",
        "type": "sl"
      },
      {
        "code": "160256",
        "type": "sl"
      },
      {
        "code": "160257",
        "type": "sl"
      },
      {
        "code": "160258",
        "type": "sl"
      },
      {
        "code": "160259",
        "type": "sl"
      },
      {
        "code": "160260",
        "type": "sl"
      },
      {
        "code": "160261",
        "type": "sl"
      },
      {
        "code": "160262",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "rg",
    "types": [
      "sl",
      "tb"
    ],
    "lists": [
      {
        "code": "150000",
        "type": "sl"
      },
      {
        "code": "150001",
        "type": "sl"
      },
      {
        "code": "150002",
        "type": "tb"
      },
      {
        "code": "150003",
        "type": "sl"
      },
      {
        "code": "150004",
        "type": "sl"
      },
      {
        "code": "150005",
        "type": "sl"
      },
      {
        "code": "150010",
        "type": "sl"
      },
      {
        "code": "150011",
        "type": "sl"
      },
      {
        "code": "150012",
        "type": "sl"
      },
      {
        "code": "150013",
        "type": "sl"
      },
      {
        "code": "150014",
        "type": "sl"
      },
      {
        "code": "150017",
        "type": "sl"
      },
      {
        "code": "150018",
        "type": "sl"
      },
      {
        "code": "150019",
        "type": "sl"
      },
      {
        "code": "150020",
        "type": "sl"
      },
      {
        "code": "150021",
        "type": "sl"
      },
      {
        "code": "150022",
        "type": "sl"
      },
      {
        "code": "150023",
        "type": "sl"
      },
      {
        "code": "150024",
        "type": "sl"
      },
      {
        "code": "150025",
        "type": "sl"
      },
      {
        "code": "150026",
        "type": "sl"
      },
      {
        "code": "150027",
        "type": "sl"
      },
      {
        "code": "150073",
        "type": "sl"
      },
      {
        "code": "150074",
        "type": "sl"
      },
      {
        "code": "150075",
        "type": "sl"
      },
      {
        "code": "150076",
        "type": "sl"
      },
      {
        "code": "150174",
        "type": "sl"
      },
      {
        "code": "150177",
        "type": "sl"
      },
      {
        "code": "150178",
        "type": "sl"
      },
      {
        "code": "150179",
        "type": "sl"
      },
      {
        "code": "150181",
        "type": "sl"
      },
      {
        "code": "150183",
        "type": "sl"
      },
      {
        "code": "150186",
        "type": "tb"
      },
      {
        "code": "150187",
        "type": "sl"
      },
      {
        "code": "150188",
        "type": "sl"
      },
      {
        "code": "150189",
        "type": "sl"
      },
      {
        "code": "150190",
        "type": "sl"
      },
      {
        "code": "150191",
        "type": "sl"
      },
      {
        "code": "150192",
        "type": "sl"
      },
      {
        "code": "150193",
        "type": "sl"
      },
      {
        "code": "150194",
        "type": "sl"
      },
      {
        "code": "150195",
        "type": "sl"
      },
      {
        "code": "150196",
        "type": "sl"
      },
      {
        "code": "150197",
        "type": "sl"
      },
      {
        "code": "150198",
        "type": "sl"
      },
      {
        "code": "150199",
        "type": "sl"
      },
      {
        "code": "150200",
        "type": "sl"
      },
      {
        "code": "150201",
        "type": "sl"
      },
      {
        "code": "150202",
        "type": "sl"
      },
      {
        "code": "150203",
        "type": "sl"
      },
      {
        "code": "150204",
        "type": "sl"
      },
      {
        "code": "150205",
        "type": "sl"
      },
      {
        "code": "150206",
        "type": "sl"
      },
      {
        "code": "150207",
        "type": "sl"
      },
      {
        "code": "150208",
        "type": "sl"
      },
      {
        "code": "150209",
        "type": "sl"
      },
      {
        "code": "150210",
        "type": "tb"
      },
      {
        "code": "150211",
        "type": "sl"
      },
      {
        "code": "150212",
        "type": "sl"
      },
      {
        "code": "150213",
        "type": "sl"
      },
      {
        "code": "150214",
        "type": "sl"
      },
      {
        "code": "150215",
        "type": "sl"
      },
      {
        "code": "150216",
        "type": "sl"
      },
      {
        "code": "150217",
        "type": "sl"
      },
      {
        "code": "150218",
        "type": "sl"
      },
      {
        "code": "150231",
        "type": "sl"
      },
      {
        "code": "150232",
        "type": "sl"
      },
      {
        "code": "150233",
        "type": "sl"
      },
      {
        "code": "150235",
        "type": "sl"
      },
      {
        "code": "150236",
        "type": "sl"
      },
      {
        "code": "150237",
        "type": "sl"
      },
      {
        "code": "150238",
        "type": "sl"
      },
      {
        "code": "150239",
        "type": "sl"
      },
      {
        "code": "150242",
        "type": "sl"
      },
      {
        "code": "150243",
        "type": "sl"
      },
      {
        "code": "150244",
        "type": "sl"
      },
      {
        "code": "150245",
        "type": "sl"
      },
      {
        "code": "150246",
        "type": "sl"
      },
      {
        "code": "150248",
        "type": "sl"
      },
      {
        "code": "150249",
        "type": "sl"
      },
      {
        "code": "150250",
        "type": "sl"
      },
      {
        "code": "150251",
        "type": "sl"
      },
      {
        "code": "150252",
        "type": "sl"
      },
      {
        "code": "150256",
        "type": "sl"
      },
      {
        "code": "150257",
        "type": "sl"
      },
      {
        "code": "150258",
        "type": "sl"
      },
      {
        "code": "150259",
        "type": "sl"
      },
      {
        "code": "150260",
        "type": "sl"
      },
      {
        "code": "150261",
        "type": "sl"
      },
      {
        "code": "150262",
        "type": "sl"
      },
      {
        "code": "150263",
        "type": "sl"
      },
      {
        "code": "150264",
        "type": "sl"
      },
      {
        "code": "150265",
        "type": "sl"
      },
      {
        "code": "150266",
        "type": "sl"
      },
      {
        "code": "150267",
        "type": "sl"
      },
      {
        "code": "150268",
        "type": "sl"
      },
      {
        "code": "150269",
        "type": "tb"
      },
      {
        "code": "150270",
        "type": "tb"
      },
      {
        "code": "150290",
        "type": "sl"
      },
      {
        "code": "150291",
        "type": "sl"
      },
      {
        "code": "150293",
        "type": "sl"
      },
      {
        "code": "150294",
        "type": "sl"
      },
      {
        "code": "150295",
        "type": "sl"
      },
      {
        "code": "150296",
        "type": "sl"
      },
      {
        "code": "150297",
        "type": "sl"
      },
      {
        "code": "150298",
        "type": "sl"
      },
      {
        "code": "150299",
        "type": "sl"
      },
      {
        "code": "150301",
        "type": "sl"
      },
      {
        "code": "150307",
        "type": "sl"
      },
      {
        "code": "150311",
        "type": "sl"
      },
      {
        "code": "150316",
        "type": "sl"
      },
      {
        "code": "150318",
        "type": "sl"
      },
      {
        "code": "150319",
        "type": "sl"
      },
      {
        "code": "150320",
        "type": "sl"
      },
      {
        "code": "150321",
        "type": "sl"
      },
      {
        "code": "150325",
        "type": "sl"
      },
      {
        "code": "150326",
        "type": "sl"
      },
      {
        "code": "150328",
        "type": "sl"
      },
      {
        "code": "150333",
        "type": "sl"
      },
      {
        "code": "150354",
        "type": "sl"
      },
      {
        "code": "150355",
        "type": "sl"
      },
      {
        "code": "150360",
        "type": "sl"
      },
      {
        "code": "150362",
        "type": "sl"
      },
      {
        "code": "150363",
        "type": "sl"
      },
      {
        "code": "150364",
        "type": "sl"
      },
      {
        "code": "150365",
        "type": "sl"
      },
      {
        "code": "150366",
        "type": "sl"
      },
      {
        "code": "150367",
        "type": "sl"
      },
      {
        "code": "150368",
        "type": "sl"
      },
      {
        "code": "150369",
        "type": "sl"
      },
      {
        "code": "150370",
        "type": "sl"
      },
      {
        "code": "150371",
        "type": "sl"
      },
      {
        "code": "150372",
        "type": "sl"
      },
      {
        "code": "150374",
        "type": "sl"
      },
      {
        "code": "150375",
        "type": "sl"
      },
      {
        "code": "150376",
        "type": "sl"
      },
      {
        "code": "150377",
        "type": "sl"
      },
      {
        "code": "150378",
        "type": "sl"
      },
      {
        "code": "150379",
        "type": "sl"
      },
      {
        "code": "150390",
        "type": "sl"
      },
      {
        "code": "150391",
        "type": "sl"
      },
      {
        "code": "150392",
        "type": "sl"
      },
      {
        "code": "150393",
        "type": "sl"
      },
      {
        "code": "150464",
        "type": "sl"
      },
      {
        "code": "150465",
        "type": "sl"
      },
      {
        "code": "150467",
        "type": "sl"
      },
      {
        "code": "150468",
        "type": "sl"
      },
      {
        "code": "150469",
        "type": "sl"
      },
      {
        "code": "150470",
        "type": "sl"
      }
    ]
  },
  {
    "interface": "yg",
    "types": [
      "sl",
      "tb"
    ],
    "lists": [
      {
        "code": "10010",
        "type": "sl"
      },
      {
        "code": "10011",
        "type": "sl"
      },
      {
        "code": "10016",
        "type": "sl"
      },
      {
        "code": "10021",
        "type": "sl"
      },
      {
        "code": "10025",
        "type": "sl"
      },
      {
        "code": "1003",
        "type": "sl"
      },
      {
        "code": "10030",
        "type": "sl"
      },
      {
        "code": "10035",
        "type": "sl"
      },
      {
        "code": "10046",
        "type": "sl"
      },
      {
        "code": "10052",
        "type": "sl"
      },
      {
        "code": "10053",
        "type": "sl"
      },
      {
        "code": "10054",
        "type": "sl"
      },
      {
        "code": "10055",
        "type": "sl"
      },
      {
        "code": "10064",
        "type": "sl"
      },
      {
        "code": "10069",
        "type": "sl"
      },
      {
        "code": "10071",
        "type": "sl"
      },
      {
        "code": "10072",
        "type": "sl"
      },
      {
        "code": "10073",
        "type": "sl"
      },
      {
        "code": "10074",
        "type": "sl"
      },
      {
        "code": "10080",
        "type": "sl"
      },
      {
        "code": "10082",
        "type": "sl"
      },
      {
        "code": "10085",
        "type": "sl"
      },
      {
        "code": "10086",
        "type": "sl"
      },
      {
        "code": "10090",
        "type": "sl"
      },
      {
        "code": "10094",
        "type": "sl"
      },
      {
        "code": "10096",
        "type": "sl"
      },
      {
        "code": "10097",
        "type": "sl"
      },
      {
        "code": "10101",
        "type": "sl"
      },
      {
        "code": "10103",
        "type": "sl"
      },
      {
        "code": "10111",
        "type": "sl"
      },
      {
        "code": "10114",
        "type": "sl"
      },
      {
        "code": "10122",
        "type": "sl"
      },
      {
        "code": "10123",
        "type": "sl"
      },
      {
        "code": "10124",
        "type": "sl"
      },
      {
        "code": "10125",
        "type": "sl"
      },
      {
        "code": "10130",
        "type": "sl"
      },
      {
        "code": "10131",
        "type": "sl"
      },
      {
        "code": "10132",
        "type": "sl"
      },
      {
        "code": "10133",
        "type": "sl"
      },
      {
        "code": "10134",
        "type": "sl"
      },
      {
        "code": "10135",
        "type": "sl"
      },
      {
        "code": "10137",
        "type": "sl"
      },
      {
        "code": "10138",
        "type": "sl"
      },
      {
        "code": "10140",
        "type": "sl"
      },
      {
        "code": "10142",
        "type": "sl"
      },
      {
        "code": "10143",
        "type": "sl"
      },
      {
        "code": "10144",
        "type": "sl"
      },
      {
        "code": "10147",
        "type": "sl"
      },
      {
        "code": "10148",
        "type": "sl"
      },
      {
        "code": "10157",
        "type": "sl"
      },
      {
        "code": "10158",
        "type": "sl"
      },
      {
        "code": "10159",
        "type": "sl"
      },
      {
        "code": "10160",
        "type": "sl"
      },
      {
        "code": "10161",
        "type": "sl"
      },
      {
        "code": "10168",
        "type": "sl"
      },
      {
        "code": "10169",
        "type": "sl"
      },
      {
        "code": "1017",
        "type": "sl"
      },
      {
        "code": "10170",
        "type": "sl"
      },
      {
        "code": "10171",
        "type": "sl"
      },
      {
        "code": "10172",
        "type": "sl"
      },
      {
        "code": "10173",
        "type": "sl"
      },
      {
        "code": "10174",
        "type": "sl"
      },
      {
        "code": "10177",
        "type": "sl"
      },
      {
        "code": "1018",
        "type": "sl"
      },
      {
        "code": "10180",
        "type": "sl"
      },
      {
        "code": "10181",
        "type": "sl"
      },
      {
        "code": "10182",
        "type": "sl"
      },
      {
        "code": "10188",
        "type": "sl"
      },
      {
        "code": "10194",
        "type": "sl"
      },
      {
        "code": "10199",
        "type": "sl"
      },
      {
        "code": "1020",
        "type": "sl"
      },
      {
        "code": "10208",
        "type": "sl"
      },
      {
        "code": "10211",
        "type": "sl"
      },
      {
        "code": "10214",
        "type": "sl"
      },
      {
        "code": "10216",
        "type": "sl"
      },
      {
        "code": "1022",
        "type": "sl"
      },
      {
        "code": "10225",
        "type": "sl"
      },
      {
        "code": "10228",
        "type": "sl"
      },
      {
        "code": "10229",
        "type": "sl"
      },
      {
        "code": "10231",
        "type": "sl"
      },
      {
        "code": "10232",
        "type": "sl"
      },
      {
        "code": "10233",
        "type": "sl"
      },
      {
        "code": "10234",
        "type": "sl"
      },
      {
        "code": "10235",
        "type": "sl"
      },
      {
        "code": "10239",
        "type": "sl"
      },
      {
        "code": "10243",
        "type": "sl"
      },
      {
        "code": "10247",
        "type": "sl"
      },
      {
        "code": "1025",
        "type": "sl"
      },
      {
        "code": "10250",
        "type": "sl"
      },
      {
        "code": "1026",
        "type": "sl"
      },
      {
        "code": "10267",
        "type": "sl"
      },
      {
        "code": "10268",
        "type": "sl"
      },
      {
        "code": "1027",
        "type": "sl"
      },
      {
        "code": "1028",
        "type": "sl"
      },
      {
        "code": "1032",
        "type": "sl"
      },
      {
        "code": "1033",
        "type": "sl"
      },
      {
        "code": "1035",
        "type": "sl"
      },
      {
        "code": "1036",
        "type": "sl"
      },
      {
        "code": "1038",
        "type": "sl"
      },
      {
        "code": "1040",
        "type": "sl"
      },
      {
        "code": "1042",
        "type": "sl"
      },
      {
        "code": "1043",
        "type": "sl"
      },
      {
        "code": "1054",
        "type": "sl"
      },
      {
        "code": "22001",
        "type": "tb"
      },
      {
        "code": "7301",
        "type": "sl"
      },
      {
        "code": "7304",
        "type": "sl"
      },
      {
        "code": "7310",
        "type": "sl"
      },
      {
        "code": "7315",
        "type": "sl"
      },
      {
        "code": "7319",
        "type": "sl"
      },
      {
        "code": "7320",
        "type": "sl"
      },
      {
        "code": "7321",
        "type": "sl"
      },
      {
        "code": "7323",
        "type": "sl"
      },
      {
        "code": "7325",
        "type": "sl"
      },
      {
        "code": "7326",
        "type": "sl"
      },
      {
        "code": "7328",
        "type": "sl"
      },
      {
        "code": "7329",
        "type": "sl"
      },
      {
        "code": "7332",
        "type": "sl"
      },
      {
        "code": "7333",
        "type": "sl"
      },
      {
        "code": "7337",
        "type": "sl"
      },
      {
        "code": "7348",
        "type": "sl"
      },
      {
        "code": "7349",
        "type": "sl"
      },
      {
        "code": "7350",
        "type": "sl"
      },
      {
        "code": "7352",
        "type": "sl"
      },
      {
        "code": "7353",
        "type": "sl"
      },
      {
        "code": "7354",
        "type": "sl"
      },
      {
        "code": "7356",
        "type": "sl"
      },
      {
        "code": "7362",
        "type": "sl"
      },
      {
        "code": "7365",
        "type": "sl"
      },
      {
        "code": "7366",
        "type": "sl"
      },
      {
        "code": "7370",
        "type": "sl"
      },
      {
        "code": "7372",
        "type": "sl"
      },
      {
        "code": "7374",
        "type": "sl"
      },
      {
        "code": "7375",
        "type": "sl"
      },
      {
        "code": "7381",
        "type": "sl"
      },
      {
        "code": "7386",
        "type": "sl"
      },
      {
        "code": "7387",
        "type": "sl"
      },
      {
        "code": "7388",
        "type": "sl"
      },
      {
        "code": "7396",
        "type": "sl"
      },
      {
        "code": "7400",
        "type": "sl"
      },
      {
        "code": "7401",
        "type": "sl"
      },
      {
        "code": "7406",
        "type": "sl"
      },
      {
        "code": "7408",
        "type": "sl"
      },
      {
        "code": "7409",
        "type": "sl"
      },
      {
        "code": "8301",
        "type": "sl"
      },
      {
        "code": "8302",
        "type": "sl"
      },
      {
        "code": "8303",
        "type": "sl"
      },
      {
        "code": "8304",
        "type": "sl"
      },
      {
        "code": "8308",
        "type": "sl"
      },
      {
        "code": "8309",
        "type": "sl"
      }
    ]
  }
]