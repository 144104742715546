<template>
  <v-container class="dashboardPage" fluid>

    <v-row justify="center">
      <v-col cols="12">
        <div
          style="background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(1,51,109,1) 20%, rgba(1,42,90,1) 80%, rgba(0,0,0,1) 100%); font-size: 1.3em"
          class="text-center py-2 mb-2">
          <v-row no-gutters>
            <v-col cols="2" class="text-left" />
            <v-col cols="8" class="text-center white--text">
              {{ $t('message.casino_text') }}
            </v-col>
            <v-col cols="2" />
          </v-row>
        </div>
        <div class="titleBar">
          <h1 class="text-center white--text">
            {{ $t(category[tab - 1].title) }}
          </h1>
        </div>
        <div>
          <v-row no-gutters class="justify-center">
            <v-col cols="12" md="10" xl="7">
              <div style="background: linear-gradient(to bottom,#0F1427, rgba(15, 20, 39, 0.75));"
                class="dashboardLayout">
                <div class="tabGroup">
                  <div>
                    <a :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                      <img src="/images/tab/football.png" alt="">
                      <p>{{ $t(category[0].text) }}</p>
                    </a>
                  </div>
                  <div>
                    <a :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                      <img src="/images/tab/casino.png" alt="">
                      <p>{{ $t(category[1].text) }}</p>
                    </a>
                  </div>
                  <div>
                    <a :class="tab == 3 ? 'active' : ''" @click="tab = 3">
                      <img src="/images/tab/slot.png" alt="">
                      <p>{{ $t(category[2].text) }}</p>
                    </a>
                  </div>
                  <div>
                    <a :class="tab == 4 ? 'active' : ''" @click="tab = 4">
                      <img src="/images/tab/history.png" alt="">
                      <p>ทายผลบอล</p>
                    </a>
                  </div>
                  <div>
                    <a :class="tab == 5 ? 'active' : ''" @click="tab = 5">
                      <img src="/images/tab/history.png" alt="">
                      <p>ทายหวย</p>
                    </a>
                  </div>
                </div>

                <v-row class="content">
                  <v-col align-self="center" class="text-center">
                    <div v-if="tab == 1">
                      <a @click="gameLaunch('m8')"><img src="/images/football/pc_m8.png" alt=""></a>
                      <a @click="gameLaunch('aa', { interface_type: 'IBC' })">
                        <img src="/images/football/pc_ibc.png" alt="">
                      </a>
                      <a @click="gameLaunch('aa', { interface_type: 'SS' })"><img src="/images/football/pc_sbo.png"
                          alt=""></a>
                      <a @click="gameLaunch('ts911')"><img src="/images/football/pc_ts911.png" alt=""></a>

                    </div>
                    <div v-if="tab == 2">
                      <a @click="gameLaunch('ae', { interface_type: 'SX' })"><img src="/images/casino/pc_ae.png"></a>
                      <a @click="gameLaunch('sa')"><img src="/images/casino/pc_sa.png" alt=""></a>
                      <a @click="gameLaunch('dg')"><img src="/images/casino/pc_dg.png" alt=""></a>
                      <a @click="gameLaunch('wm')"><img src="/images/casino/pc_wm.png" alt=""></a>
                      <a @click="gameLaunch('ab')"><img src="/images/casino/pc_ab.png" alt=""></a>
                      <a @click="gameLaunch('zb')"><img src="/images/casino/pc_zb.png" alt=""></a>
                    </div>
                    <div v-if="tab == 3">
                      <v-tabs-items v-model="tab_game" class=" mt-3" style="background: none;">
                        <v-tab-item key="sexy" style="background: none;">
                          <v-row>
                            <v-col cols="4" sm="3"  md="2"  @click="gameLaunch('ae', { interface_type: 'FC' })">
                              <v-img lazy-src="/images/logo_loading.png" src="/images/game/pc_fc.png" alt="" />
                            </v-col>
                            <v-col cols="4" sm="3"  md="2"  @click="gameLaunch('ae', { interface_type: 'KM' })">
                              <v-img lazy-src="/images/logo_loading.png" src="/images/game/pc_km.png" alt="" />
                            </v-col>
                            <v-col cols="4" sm="3"  md="2"  @click="gameLaunch('ae', { interface_type: 'PG' })">
                              <v-img lazy-src="/images/logo_loading.png" src="/images/game/pc_pg.png" alt="" />
                            </v-col>
                            <v-col cols="4" sm="3"  md="2"  @click="gameLaunch('ae', { interface_type: 'PP' })">
                              <v-img lazy-src="/images/logo_loading.png" src="/images/game/pc_pp.png" alt="" />
                            </v-col>
                            <v-col cols="4" sm="3"  md="2"  v-for="(item, i) in games" :key="item.interface" @click="tab_game = i + 1">
                              <v-img lazy-src="/images/logo_loading.png"
                                :src="`/images/game/pc_${item.interface}.png`" />
                            </v-col>
                          </v-row>
                        </v-tab-item>
                        <v-tab-item v-for="item in games" :key="item.interface" style="background: none; padding-top: 40px;">
                          <v-card style="background: none;">
                            <v-card-text>
                              <v-sheet class="mx-1 my-2"
                                style="background: rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(1, 51, 109) 0%, rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgb(1, 51, 109) 100%) repeat scroll 0% 0%;"
                                rounded>
                                <div class="d-flex align-center">
                                  <div stype="background:black; width:100%">
                                    <v-btn icon @click="tab_game = 0">
                                      <v-icon color="white" style="font-size: 30px">mdi-arrow-left-bold-box</v-icon>
                                    </v-btn>
                                  </div>

                                  <div class="flex-grow-1 orange--text">
                                    <v-img :src="`/images/game/icon/${item.interface}.png`" max-height="50"  max-width="100"  ratio="1" class="mx-auto"  contain/>
                                  </div>
                                </div>

                              </v-sheet>


                              <v-tabs v-model="tab_game_type[item.interface]" background-color="transparent"
                                color="orange" show-arrows dark grow class="mb-5">
                                <v-tabs-slider color="yellow" class="my-1"></v-tabs-slider>
                                <v-tab v-for="gameType in item.types" :key="`${item.interface}-${gameType}`"
                                  class="mx-2"
                                  style="background: #0f1426; margin: 3px; margin-right: 3px; margin-left: 3px;">
                                  <div class="gold--border" style="background: none;">
                                    {{ $t(`game.${gameType}`) }}
                                  </div>
                                </v-tab>
                              </v-tabs>
                              <v-tabs-items v-model="tab_game_type[item.interface]" style="background: none;">
                                <v-tab-item v-for="gameType in item.types" :key="`${item.interface}-${gameType}`">
                                  <a v-for="game in item.lists.filter(g => g.type == gameType)"
                                    @click="gameLaunch(item.interface, { game_code: game.code })"
                                    :key="`${item.interface}-${game.code}-${uid()}`">
                                    <v-img lazy-src="/images/logo_loading.png"
                                      :src="`/images/game/${item.interface}/${game.code}.png`" alt="" />
                                  </a>
                                </v-tab-item>

                              </v-tabs-items>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </div>
                    <div v-if="tab == 4">
                      <sport></sport>
                    </div>
                    <div v-if="tab == 5">
                      <lottery></lottery>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pb-7">
                  <v-col cols="12" sm="6" class="text-center text-sm-right">
                    <router-link :to="{ name: 'member-deposit' }" class="btnBank text-center">
                      <img src="/images/money.png" alt="" height="45px" class="mr-4">
                      <span>{{ $t('deposit.text') }}</span>
                    </router-link>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-center text-sm-left">
                    <router-link :to="{ name: 'member-withdraw' }" class="btnBank text-center">
                      <img src="/images/withdrawal.png" alt="" height="45px" class="mr-4">
                      <span>{{ $t('withdraw.text') }}</span>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

//import { httpsCallable } from 'firebase/functions'
import Sport from './Sport.vue'
import Lottery from './Lottery.vue'
import { mapActions, mapGetters } from 'vuex'
import games from '../../configs/games'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    Sport,
    Lottery
  },
  data() {
    return {
      tab_game: 0,
      tab_game_type: games.reduce((r, n) => {
        r[n.interface] = 0
        return r
      }, {}),
      game_categorys: [
        'sexy', ...(games['game_categorys'] || [])
      ],
      games: games,
      sports: [

      ],
      tab: 1,
      category: [
        {
          title: 'message.spots',
          text: 'spots.text',
        },
        {
          title: 'message.casino',
          text: 'casino.text'
        },
        {
          title: 'message.game',
          text: 'game.text'
        },
        {
          title: 'message.accountant',
          text: 'accountant.text'
        },
        {
          title: 'message.history',
          text: 'history.text'
        },
        {
          title: 'message.game',
          text: 'game.text'
        },
        {
          title: 'message.game',
          text: 'game.text'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('member', ['member'])
  },
  methods: {
    ...mapActions('game', ['launch']),
    ...mapActions('app', ['page_loading']),
    uid() {
      return uuidv4()
    },
    async gameLaunch(game_id, params = {}) {
      this.page_loading(true)
      await this.launch({ game_id, ...params })
        .then(data => {
          if (data && !data.error && data.url) window.open(data.url, '_blank').focus()
        })
      this.page_loading(false)
    },
  },
}
</script>
<style lang="scss" scoped>
.dashboardPage::v-deep {

  .v-slide-group__next i,
  .v-slide-group__prev i {
    color: orange !important;
    font-size: 43px;
    margin-top: 33px;
    margin-bottom: auto;
  }

  .v-slide-group__prev i.v-icon--disabled,
  .v-slide-group__next i.v-icon--disabled {
    color: rgb(69, 47, 7) !important;
  }

  .tab-game.v-tabs {
    height: 120px;
  }



  .dashboardLayout {
    padding: 0 54px;
  }

  .titleBar {
    background: linear-gradient(0deg, #141b34 0%, #212c54 100%);
    padding: 4px 0;

    h1 {
      font-size: 1.3em;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .tabGroup {
    text-align: center;

    >div {
      display: inline-block;
      width: 18%;
      text-align: center;

      a {
        display: inline-block;
        background: #252525;
        width: 80%;
        border-radius: 0px 0px 20px 20px;
        height: 100px;
        color: #fff;
        padding-top: 20px;
        vertical-align: top;

        &.active {
          border: 2px solid #444543;
          height: 130px;
          padding-top: 35px;
        }

        p {
          line-height: 1em;
          margin-bottom: 0;
        }

        img {
          height: 32px;
        }
      }
    }
  }

  .content {
    min-height: 450px;
    text-align: center;

    a {
      display: inline-block;
      width: 15%;
      margin: 0 10px 10px 10px;

      img {
        width: 100%;
      }
    }
  }

  .btnBank {
    display: inline-block;
    background: #1f2a4f;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 1.5em;
    width: 50%;
    padding: 15px 0;

    img {
      vertical-align: middle;
    }
  }
}

.defaultBg {
  background-image: url('/../../images/bg3.jpeg');
  background-position: center center;
  background-size: cover;
}

@media (max-width: 959px) {
  .dashboardPage {
    .dashboardLayout {
      padding: 0 0;
    }

    .tabGroup {
      >div {
        a {
          width: 90%;
          padding-top: 20px;
          vertical-align: top;
          height: 80px;
          border-radius: 0;

          &.active {
            border: 2px solid #444543;
            height: 90px;
            padding-top: 25px;
          }

          p {
            line-height: 1em;
            margin-bottom: 0;
            font-size: 0.7em;
          }

          img {
            height: 20px;
          }
        }
      }
    }

    .content {
      padding: 40px 0;

      a {
        display: inline-block;
        width: 32%;
        margin: 0 10px 10px 10px;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  .dashboardPage {
    .dashboardLayout {
      padding: 0 20px;
    }

    .tabGroup {
      >div {
        a {
          width: 90%;
          padding-top: 20px;
          vertical-align: top;

          &.active {
            border: 2px solid #444543;
            height: 110px;
            padding-top: 30px;
          }

          p {
            line-height: 1em;
            margin-bottom: 0;
          }

          img {
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
