<template>
  <form-history-layout noHeader :type="'event_sport'" :items="[]">
    <template v-slot:formLayout>
      <div class="text-end my-2">
        ท่านมี {{ profile.coin_total }} เหรียญ
      </div>
      <div class="text-center text-h4">
        ทายหวย {{ lottery.id || '' }}
      </div>
      <div class="text-center">
        รายการ: {{ lottery.name || 'ไม่พบกิจกรรม' }}
      </div>
      <div class="text-center">
        ประจำวันที่: {{ lottery.start_at || '-' }}
      </div>
      <div v-if="canBuy">
        <div class="my-5 my-md-4">
          <div class="text-center">
            <span>รางวัลที่ 1</span>
            <v-text-field v-model="guess.no1" :rules="rules(6)" counter outlined solo rounded :readonly="!canBuy">
              <template v-slot:counter>
                <span class="orange--text">รางวัล 188,888 บาท</span>
              </template>
            </v-text-field>
          </div>
        </div>
        <div class="d-md-flex justify-space-between  my-md-4">
          <div class="text-center">
            <span>รางวัลเลขหน้า 3 ตัว หมุน 2 รอบ</span>
            <v-text-field v-model="guess.front3c" :rules="rules(3)" counter outlined rounded solo :readonly="!canBuy">
              <template v-slot:counter>
                <span class="orange--text">รางวัล 8,888 บาท</span>
              </template>
            </v-text-field>

          </div>
          <div class="text-center">
            <span>รางวัลเลขท้าย 3 ตัว หมุน 2 รอบ</span>
            <v-text-field v-model="guess.back3c" :rules="rules(3)" counter outlined rounded solo :readonly="!canBuy">
              <template v-slot:counter>
                <span class="orange--text">รางวัล 8,888 บาท</span>
              </template>
            </v-text-field>

          </div>
        </div>
        <div class="d-md-flex justify-space-between my-md-4">
          <div class="text-center">
            <span>รางวัลเลขท้าย 2 ตัวล้าง</span>
            <v-text-field v-model="guess.down2c" :rules="rules(2)" counter outlined rounded solo :readonly="!canBuy">
              <template v-slot:counter>
                <span class="orange--text">รางวัล 2,888 บาท</span>
              </template>
            </v-text-field>

          </div>
          <div class="text-center">
            <span>วิ่งล้าง (เลขท้ายสองตัว)</span>
            <v-text-field v-model="guess.run" :rules="rules(1)" counter outlined rounded solo :readonly="!canBuy">
              <template v-slot:counter>
                <span class="orange--text">รางวัล 388 บาท</span>
              </template>
            </v-text-field>

          </div>
        </div>
        <div v-if="canBuy" class="d-flex justify-end my-2">
          <v-btn v-if="canBuy" @click="submit" :loading="isLoading">ทายผล</v-btn>
        </div>
      </div>
      <div v-else-if="lottery.pending" class="my-3">
        <div class="d-flex justify-center">
          <v-card class="mx-1 flex-grow-1">
            <v-card-text>
              <div class="text-center text-h5 my-3">ผลสลากกินแบ่งรัฐบาล</div>
              <div class="mx-1">
                <v-simple-table>
                  <thead>
                    <tr>
                      <td>รางวัล</td>
                      <td>หมายเลขที่ออก</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>รางวัลที 1</td>
                      <td>รอผล</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 3 ตัว</td>
                      <td>รอผล</td>
                    </tr>
                    <tr>
                      <td>เลขท้าย 3 ตัว</td>
                      <td>รอผล</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 2 ตัว</td>
                      <td>รอผล</td>
                    </tr>
                    <tr>
                      <td>เลขวิ่ง</td>
                      <td>รอผล</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mx-1">
            <v-card-text>
              <div class="text-center text-h5 my-3">ทายผล</div>
              <div class="mx-1">
                <v-simple-table>
                  <thead>
                    <tr>
                      <td>รางวัล</td>
                      <td>หมายเลขที่ทาย</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>รางวัลที 1</td>
                      <td>{{ lotteryNow.guess.no1 || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 3 ตัว</td>
                      <td>{{ lotteryNow.guess.front3c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขท้าย 3 ตัว</td>
                      <td>{{ lotteryNow.guess.back3c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 2 ตัว</td>
                      <td>{{ lotteryNow.guess.down2c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขวิ่ง</td>
                      <td>{{ lotteryNow.guess.run || '-' }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div v-else>
        <v-sheet class="text-center my-5 py-5" color="white" elevation="1" rounded>
          <span>ไม่พบกิจกรรม</span>
        </v-sheet>
      </div>
      <div class="text-subtitle">
        กติกาทายผลฉลากกินแบ่งรัฐบาลประเทศไทย
        <ol>
          <li>กติการทายผลบอล</li>
          <li>ต้องใช้เหรียญ {{ lottery.price || '-' }} เหรียญในการทาย 1 งวด สามารถทายได้ 1 ครั้ง</li>
          <li>ทางเวปจะอ้างอิงจากการประกาศผล ฉลากกินแบ่งรัฐบาลประเทศไทย ในทุกๆวันที่ 1 และ 16 ของในแต่ละเดือน</li>
          <li>ท่านจะได้รับเงินรางวัลต่อเมื่อทายผล ฉลากกินแบ่งรัฐบาลประเทศไทย
            ถูกต้องโดยจะได้รับรางวัลที่มียอดรางวัลมากที่สุด 1 รางวัลเท่านั้น</li>
          <li>ak55bet ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นนี้ ไม่ว่ากรณีใดหรือเวลาใดก็ตาม โดยจะไม่แจ้งให้ท่านทราบล่วงหน้า
          </li>
        </ol>
      </div>
    </template>

    <template v-slot:table>
      <thead>
        <tr>
          <th>#</th>
          <th>ทายผล</th>
          <th rowspan="2">ผล</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in lotterylist" :key="index">
          <td>{{ item.lottery_id }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.comment }} {{ item.description ? `#${item.description}"` : '' }}</td>
          <td width="70px">
            <v-btn @click="openDialogShow(item)" icon>
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>

    <template v-slot:outter>
      <v-dialog v-if="current" v-model="dialogShow">
        <div class="d-flex justify-center">
          <v-card class="mx-1 flex-grow-1">
            <v-card-text>
              <div class="text-center text-h5 my-3">ผลสลากกินแบ่งรัฐบาล</div>
              <div class="mx-1">
                <v-simple-table>
                  <thead>
                    <tr>
                      <td>รางวัล</td>
                      <td>หมายเลขที่ออก</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>รางวัลที 1</td>
                      <td>{{ current.result ? current.result.no1 : 'รอผล' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 3 ตัว</td>
                      <td>{{ current.result ? (current.result.front3c || []).join(' , ') : 'รอผล' }}</td>
                    </tr>
                    <tr>
                      <td>เลขท้าย 3 ตัว</td>
                      <td>{{ current.result ? (current.result.back3c || []).join(' , ') : 'รอผล' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 2 ตัว</td>
                      <td>{{ current.result ? current.result.down2c : 'รอผล' }}</td>
                    </tr>
                    <tr>
                      <td>เลขวิ่ง</td>
                      <td>{{ current.result ? current.result.run : 'รอผล' }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mx-1">
            <v-card-text>
              <div class="text-center text-h5 my-3">ทายผล</div>
              <div class="mx-1">
                <v-simple-table>
                  <thead>
                    <tr>
                      <td>รางวัล</td>
                      <td>หมายเลขที่ทาย</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>รางวัลที 1</td>
                      <td>{{ current.guess.no1 || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 3 ตัว</td>
                      <td>{{ current.guess.front3c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขท้าย 3 ตัว</td>
                      <td>{{ current.guess.back3c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขหน้า 2 ตัว</td>
                      <td>{{ current.guess.down2c || '-' }}</td>
                    </tr>
                    <tr>
                      <td>เลขวิ่ง</td>
                      <td>{{ current.guess.run || '-' }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </template>

  </form-history-layout>
</template>
<script>
import FormHistoryLayout from '../../layouts/sub_layouts/FormHistoryLayout.vue'
import { mapGetters, mapActions } from 'vuex'
// import moment from 'moment'
export default {
  components: {
    FormHistoryLayout,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      guess: {
        no1: '',
        front3c: '',
        back3c: '',
        down2c: '',
        run: ''
      },
      dialogShow: false,
      current: {
        result: {},
        guess: {}
      }
    }
  },
  methods: {
    ...mapActions('lottery', ['buyLottery']),
    async submit() {
      this.isLoading = true
      await this.buyLottery({
        id: this.lottery.id,
        guess: this.guess
      })
      this.$set(this, 'guess', {
        no1: '',
        front3c: '',
        back3c: '',
        down2c: '',
        run: ''
      })
      this.isLoading = false
    },
    rules(length) {
      return [
        v => !!v || 'กรุณากรอหมายเลข',
        v => String(v).length == length || `กรุณากรอกจำนวนให้ครบ ${length} หลัก`,
        v => /^[0-9]+$/.test(String(v)) || 'กรุณากรอกให้ถูกต้อง'
      ]
    },
    openDialogShow(item) {
      this.$set(this.current, 'guess', item.guess)
      this.$set(this.current, 'result', item.result || {})
      this.dialogShow = true
    }
  },
  computed: {
    ...mapGetters('app', ['lottery']),
    ...mapGetters('lottery', ['lotteries', 'lotteryQueues']),
    ...mapGetters('member', ['profile']),
    canBuy() {
      const lottery = this.lotterylist.filter(i => i.lottery_id === this.lottery.id)[0]
      return !lottery
    },
    memberGuess() {
      return null
    },
    lotterylist() {
      const list = []
      list.push(...Object.values(this.lotteryQueues))
      list.push(...this.lotteries)
      return list.sort((a, b) => a.lottery_id < b.lottery_id ? 1 : -1)
    },
    lotteryNow() {
      return this.lotterylist.filter(i => i.lottery_id === this.lottery.id)[0]
    }
  },
  watch: {
    lottery() {
      alert(1)
    }
  }

}
</script>

<style scoped>
.v-input::v-deep input {
  text-align: center;
}
</style>
